<template>
  <section class="product-list-heading box-my-account mb-0">
    <div class="container-fluid">
      <div class="row">
        <ProfileNavbar />
        <div class="col-md-9 py-5 px-lg-5">
          <h2>My Details</h2>
          <form @submit.prevent class="mt-4">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div class="clearfix">
                    <div class="input-line">
                      <input
                        type="text"
                        :value="userData.full_name"
                        readonly
                        required
                      />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label class="focusForm">Full Name</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="clearfix">
                    <div class="input-line">
                      <input
                        type="text"
                        :value="userData.email"
                        readonly
                        required
                      />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label class="focusForm">Email</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="clearfix">
                    <div class="box-date">
                      <div
                        class="input-line input-group date"
                        data-provide="datepicker"
                        @click="userData.birth_day ? {} : $router.push('/edit-profile')"
                      >
                        <input
                          type="text"
                          v-model="userData.birth_day"
                          placeholder="Hey, tell us your birthday! :D"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">Birthday</label>
                        <div class="input-group-addon">
                          <span class="glyphicon glyphicon-th"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="clearfix">
                    <div class="input-line" @click="userData.phone ? {} : $router.push('/edit-profile')">
                      <input
                        type="text"
                        v-model="userData.phone"
                        placeholder="Let's add your phone number"
                        readonly
                      />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label class="focusForm">Phone</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <!-- <template v-if="resellerData && resellerData.is_active">
            <h2>Business Partner Details</h2>
            <form @submit.prevent class="mt-4">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="input-line">
                        <input
                          type="text"
                          :value="resellerData.region.name"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">Province</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="input-line">
                        <input
                          type="text"
                          :value="resellerData.district.name"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">District</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="box-date">
                        <div
                          class="input-line input-group date"
                          data-provide="datepicker"
                        >
                          <input
                            type="text"
                            :value="resellerData.township.name"
                            readonly
                          />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <label class="focusForm">Subdistrict</label>
                          <div class="input-group-addon">
                            <span class="glyphicon glyphicon-th"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="input-line">
                        <input
                          type="text"
                          :value="resellerData.address"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">Address</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="input-line">
                        <input
                          type="text"
                          :value="resellerData.postal_code"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">Postal Code</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="input-line">
                        <input
                          type="text"
                          :value="resellerData.bank_info.replace(/\d+-/i, '') || 'N/A'"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">Bank Info</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-group">
                    <div class="clearfix">
                      <div class="input-line">
                        <input
                          type="text"
                          :value="resellerData.bank_account || 'N/A'"
                          readonly
                        />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label class="focusForm">Bank Account</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>

          <template v-if="resellerData && resellerData.status_verify">
            <h2>Referral Code</h2>
            <div
              class="d-md-inline-block rounded p-3"
              style="background-color: #d6b57e"
            >
              <div class="row">
                <div class="col-auto">
                  <div
                    class="
                      d-flex
                      align-items-center
                      bg-white
                      p-2
                      border-gold
                      rounded
                    "
                  >
                    <p class="m-0 overflow-auto">
                      {{ resellerData.link_referral }}
                    </p>
                    <a class="pl-2" href="#" @click="copy()"
                      ><img
                        class="mw-100"
                        width="24"
                        src="@/assets/img/copy.png"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <div class="referral-code pt-2">
                <p class="m-0 overflow-auto bold">Share your referral code</p>
              </div>
            </div>
          </template> -->
          <!-- <div
            v-if="!resellerData || (resellerData && !resellerData.is_active)"
            class="d-flex d-lg-block justify-content-center"
          >
            <button
              class="btn btn-wz-proceed"
              v-b-modal.modal-register-reseller
            >
              Become a Business Partner
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { toaster } from "@/_helpers";
import ProfileNavbar from "@/components/mains/ProfileNavbar";
import moment from "moment";

export default {
  name: "DetailProfile",
  components: {
    ProfileNavbar,
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    showUpgradeForm() {
      return this.$route.path.indexOf("/upgrade-bus-partner") == 0
    },
  },
  mounted() {
    this.userData.birth_day = this.formatDate(this.userData.birth_day);
    if (this.showUpgradeForm && !this.resellerData) {
      setTimeout(() => this.$router.push("/profile"), 100)
      this.$bvModal.show("modal-register-reseller")
    }
  },
  
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.resellerData.link_referral);
      toaster.make(
        "Referral code has been copied to your clipboard",
        "success"
      );
    },
    formatDate(value) {
      return value ? moment(String(value)).format("D MMMM YYYY") : value;
    },
  },
};
</script>

<style scoped>

@media only screen and (max-width: 680px) {
  .referral-code .footer-logo {
    font-size: 4vw;
  }
}
</style>
